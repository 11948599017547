import React from "react"
import postStyles from "./post.module.scss"
import { Link } from "gatsby"

export default function Post(props) {

  const background = props.photo

  return <div className={postStyles.card}>
    <a href={props.link}>
      <div 
        className={postStyles.image} 
        alt={props.photoDescription} 
        style={{backgroundImage: "url(" + background + ")"}}>
        
      </div>
    </a>
    <h2>{props.title}</h2>
    <p className={postStyles.date}>{props.date}</p>
    <p>{props.description}</p>
    <Link className={postStyles.link} to={props.link}>View trip</Link>
  </div>
}